import type { ComposerTranslation } from '@nuxtjs/i18n/dist/runtime/composables';
import { getCounterData, getImage, getPriceData, getLabelData, getCoinsData } from '../cases/adapter';
import type { ICasesItem } from '../cases/client.types';
import type { TFreeCasesApiResponse } from './api.types';

export const transform = (apiData: TFreeCasesApiResponse, t: ComposerTranslation): ICasesItem[] => {
  const result: ICasesItem[] = [];

  for (const currentCase of apiData) {
    currentCase.available = true; // Все бесплатные кейсы доступны по умолчанию

    const priceData = getPriceData(currentCase.priceData);
    priceData.price = 0; // У всех бесплатных кейсов цена = 0

    result.push({
      coinsData: currentCase.tastyCoins ? getCoinsData(currentCase.tastyCoins) : null,
      counterData: getCounterData(currentCase.statisticData),
      disabled: !currentCase.available,
      id: currentCase.id,
      image: getImage(currentCase.imageData.image),
      labelData: getLabelData(currentCase, t),
      link: currentCase.name,
      name: currentCase.label,
      priceData,
    });
  }

  return result;
};
